import type { ISO8601Date } from './common'
import type { QuestionSetIdentPlusAlts } from '.'

export const BadgeKey = ['spelling-champion-training-gb', 'spelling-champion-training-us', 'number-champion-training-gb', 'number-champion-training-us', 'phonics-champion-training-gb', 'phonics-champion-training-us'] as const
export type BadgeKey = typeof BadgeKey[number]

export const BadgeType = ['champion'] as const
export type BadgeType = typeof BadgeType[number]

export const BadgeCriteriaType = ['spelling-game', 'number-game', 'quiz-game', 'lesson'] as const
export type BadgeCriteriaType = typeof BadgeCriteriaType[number]

export interface BadgeRecord {
  id: number
  key: BadgeKey
  type: BadgeType
  earnings: number
  description: string
  title: string
}

export interface EarnableBadge {
  id: number
  key: BadgeKey
  type: BadgeType
  criteria: EarnableBadgeCriteria[]
  title: string
  description: string
}

export type EarnableBadgeCriteria = EarnableBadgeCriteriaSpelling | EarnableBadgeCriteriaLesson | EarnableBadgeCriteriaNumber | EarnableBadgeCriteriaQuiz

export interface EarnableBadgeCriteriaBase {
  id: number
  badge_id: number
  quantity: number
}

export interface EarnableBadgeCriteriaSpelling extends EarnableBadgeCriteriaBase {
  action: 'spelling-game'
  list_ident: string
  list_id: number
  list_title: string
}

export interface EarnableBadgeCriteriaLesson extends EarnableBadgeCriteriaBase {
  action: 'lesson'
  lesson_ident: string
  lesson_title: string
}

export interface EarnableBadgeCriteriaQuiz extends EarnableBadgeCriteriaBase {
  action: 'quiz-game'
  quiz_ident: QuestionSetIdentPlusAlts
  quiz_title: string
}

export interface EarnableBadgeCriteriaNumber extends EarnableBadgeCriteriaBase {
  action: 'number-game'
  number_game: string
  number_game_title: string
}

export interface UserBadge {
  user_id: number
  badge_id: number
  date_started: ISO8601Date
  progress: number
  date_earned: ISO8601Date | null
  badge: EarnableBadge
}
