import en_gb_swears from "./profanity/en-gb";
import {afrikaans_swears, afrikaans_whitelist} from "./profanity/af-za";
import { DictionaryLanguage } from "../i18n";

type ProfanityList = Partial<Record<DictionaryLanguage, string[]>>

export const profanity: ProfanityList = {
  'en_GB': [...en_gb_swears],
  'af_ZA': [...en_gb_swears, ...afrikaans_swears]
}

export const profanityWhitelist: ProfanityList = {
  'en_GB': [],
  'af_ZA': [...afrikaans_whitelist]
}