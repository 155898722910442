<template>
  <img :crossorigin="crossorigin" :src="qualifiedUrl" :alt="alt">
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

/**
 * Replace images with smart images
 * Search: <img ([^>]+)>
 * Replace: <smart-image $1 />
 */
@Component({})
export default class SmartImage extends Vue {
  public static BaseURL = ''

  @Prop({ required: true })
  private readonly src!: string

  @Prop({ required: false })
  private readonly alt?: string

  @Prop({ default: undefined })
  private readonly crossorigin?: string

  private get qualifiedUrl () {
    if (this.src.indexOf('http:') === 0 || this.src.indexOf('https:') === 0 || this.src.indexOf('data:') === 0) {
      return this.src
    }

    return SmartImage.BaseURL + this.src
  }

  public mounted () {
    if (!this.alt) {
      // eslint-disable-next-line
      console.warn('Smart Image missing alt text for:', this.src)
    }
  }
}
</script>
