import type { GrammarGameType } from './grammar'

export interface GrammarGameInfo {
  id: number
  title: string
  description: string
  ident: GrammarGameIdent
  type: GrammarGameType
}

export const GrammarGameIdent = ['dog', 'maze', 'fish', 'hippo', 'bracket', 'pirates', 'pair', 'dream'] as const
export type GrammarGameIdent = typeof GrammarGameIdent[number]

export const grammarGames: GrammarGameInfo[] = [
  { id: 0, title: 'Perfect Pups', ident: 'dog', type: 'multi', description: 'Wager treats to find the best behaved dogs' },
  { id: 1, title: 'Mouse Mansion', ident: 'maze', type: 'multi', description: 'Solve the maze and avoid the ghosts!' },
  { id: 2, title: 'Fish Fast', ident: 'fish', type: 'multi', description: 'Ready for a fishing challenge?' },
  { id: 3, title: 'Honey Hippo', ident: 'hippo', type: 'multi', description: 'Test your knowledge to win big!' },
  { id: 4, title: 'Bracket Bridge', ident: 'bracket', type: 'bracket', description: 'Add brackets to sentences with the mechanical claw.' },
  { id: 5, title: 'Punctuation Pirates', ident: 'pirates', type: 'punctuation', description: 'Punch out those pirates\' sails with proper punctuation power!' },
  { id: 6, title: 'Pairs to the Pond', ident: 'pair', type: 'match', description: 'Match the pairs and get the duckling to the pond!' },
  { id: 7, title: 'Sweet Dream Sentences', ident: 'dream', type: 'sentence', description: 'Complete the sentences to give sweet dreams to the snoozing bear.' }
  // { id: 7, title: 'Sorting', ident: 'sorting', type: 'matching', description: 'Coming Soon' },
  // { id: 8, title: 'Sentence Forming', ident: 'sentences', type: 'sentences', description: 'Coming Soon' },
  // { id: 9, title: 'Unkown 1', ident: 'unknown', type: 'matching', description: 'Coming Soon' },
  // { id: 10, title: 'Unkown 2', ident: 'unknown', type: 'matching', description: 'Coming Soon' },
  // { id: 11, title: 'Unkown 3', ident: 'unknown', type: 'matching', description: 'Coming Soon' }
]

export function getGrammarGameByIdent(ident: GrammarGameIdent) {
  return grammarGames.find(x => x.ident === ident)
}
