<template>
  <div class="FileAttachmentField">
    <b-field label="Name" horizontal>
      <b-input v-model="value.name" placeholder="Name" expanded />
    </b-field>
    <b-field label="Desc." horizontal>
      <b-input v-model="value.description" placeholder="Description" expanded type="textarea" />
    </b-field>
    <div class="block">
      <b-checkbox
        v-model="value.downloadable"
        native-value="downloadable"
      >
        Downloadable
      </b-checkbox>
      <b-checkbox
        v-model="value.viewable"
        native-value="viewable"
      >
        Viewable
      </b-checkbox>
      <b-checkbox
        v-model="value.featured"
        native-value="featured"
        @input="$emit('featured-changed', $event)"
      >
        Featured
      </b-checkbox>
      <slot name="options" />
    </div>
    <b-field label="File" horizontal>
      <file-input :record="value" field="file" @input="$emit('file-attached', $event)" />
    </b-field>

    <b-field
      v-if="isMp4"
      label="Poster"
      horizontal
      message="A rectangular image to use as a placeholder for videos. (optional)"
    >
      <ImageInput
        :record="value"
        field="poster_image"
        @input="onImageSelected"
      />
    </b-field>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FileAttachment } from '../api'

@Component({ components: {}, props: ['value'] })
export default class FileAttachmentField extends Vue {
  @Prop({ required: true })
  private readonly value!: FileAttachment

  private placeholderError: string | null = null

  get isMp4 (): boolean {
    return this.value.new_file?.extname === '.mp4' || this.value.file?.extname === '.mp4'
  }

  private onFileAttached (file: File): void {
    this.$emit('file-attached', file)

    if (!this.isMp4) {
      this.placeholderError = null
    }
  }

  private onImageSelected (image: File): void {
    if (image) {
      this.placeholderError = null
    }
  }
}
</script>
