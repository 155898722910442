import gb from './en-gb'
import type { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'AU',
  County: 'County / Region',
  SchoolDistricts: 'School Districts / trusts.',
  currency: 'A$',
  spellingPersonalPrice: '134.98',
  spellingTeacherPrice: '210.00',
  spellingPremiumPrice: '7.50',
  spellingAppPrice: '4.49',
  pupilsPerSpellingClassroom: '28',
  natCurriculum: 'Australian Curriculum',
  natCurr2: 'Australian Curriculum',
  customText: 'Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  spellingCurriculumKey: 'spelling-shed-scheme-au',
  spellingCurriculumId: '8',
  spagCurriculumKey: 'spag-australia',
  spagCurriculumId: '14',
  mathsCurriculumKey: 'maths-shed-teaching-slides-aus',
  mathsPersonalPrice: '134.98',
  mathsTeacherPrice: '210.00',
  mathsPremiumPrice: '7.50',
  mathsAppPrice: '4.49',
  pupilsPerMathsClassroom: '28',
  pupil: 'student',
  Pupil: 'Student',
  pupils: 'students',
  Pupils: 'Students',
  scheme: 'curriculum',
  SchemeText: 'Curriculum',
  personalised: 'personalized',
  personalise: 'personalize'
}

export default lang
