<template>
  <div class="ErrorModal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        💣 An Error Occurred: {{ err.message }}
      </p>
    </header>
    <section class="modal-card-body">
      <div v-if="!reportCode" class="ErrorModal__message">
        <p>We're sorry an error occurred.</p>
      </div>
      <div v-else class="ErrorModal__message">
        <p>Thank you. Your error report code is <b>{{ reportCode }}</b>. Please quote this number when calling EdShed support.</p>
      </div>

      <button
        type="button"
        class="button is-primary ErrorModal__detailButton"
        @click="detailOpen = !detailOpen"
      >
        {{ detailOpen ? 'Hide Details' : 'Show Details...' }}
      </button>

      <div v-if="detailOpen" class="ErrorModal__detail">
        <pre>{{ err.stack }}</pre>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        v-if="!reportCode"
        v-async="sendErrorReport"
        type="button"
        class="button is-info"
      >
        Send Error Report
      </button>

      <button
        ref="closeButton"
        type="button"
        class="button is-primary"
        @click="closeErrorModal()"
      >
        Close
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'
import { Api, ErrorReportInfo } from '../api'
import ModalMixin from '../mixins/ModalMixin'

@Component({ components: {} })
export default class ErrorModal extends mixins(ModalMixin) {
  @Prop({ required: true })
  public err!: Error

  @Prop({ required: true })
  public action!: string | null

  private detailOpen = false
  private reportCode: number | null = null

  public async mounted () {
    await this.$nextTick();

    (this.$refs.closeButton as HTMLButtonElement).focus()
  }

  public closeErrorModal () {
    this.$emit('on-close')
  }

  public async sendErrorReport () {
    const report: ErrorReportInfo = {
      user_id: null,
      url: window.location.href,
      user_agent: navigator.userAgent,
      action: this.action,
      message: this.err.message,
      stack: this.err.stack || ''
    }

    const { id } = await Api.sendErrorReport(report)

    this.reportCode = id!
  }
}
</script>

<style lang="scss">
.ErrorModal {
  /* Move footer buttons to the right (consistency across Buefy versions) */
  &.modal-card .modal-card-foot {
    justify-content: flex-end;
  }

  &__message {
    margin-bottom: 10px;
  }

  &__detailButton {
    margin-bottom: 10px;
  }
}
</style>
