import { CountryCode, ZoneCode } from './country'
import { Bit } from './common'

export const AddressType = ['location_address_id', 'billing_address_id'] as const
export type AddressType = typeof AddressType[number]

export interface AddressInfo {
  id: number
  name: string,
  email: string,
  phone_number: string | null
  address_1: string,
  address_2: string,
  county: string,
  country: string,
  country_code: CountryCode,
  lat: number | null,
  long: number | null,
  postcode: string,
  town: string
  school_id: number | null
  is_deleted?: Bit
  zone_code: ZoneCode | null
}

export interface AddressToAdd {
  name: string,
  email: string,
  phone_number: string | null
  address_1: string,
  address_2: string,
  county: string,
  country: string,
  country_code: CountryCode,
  lat: number | null,
  long: number | null,
  postcode: string,
  town: string
  school_id: number | null
  is_deleted?: Bit
  zone_code: ZoneCode | null
}

export type AddressToEdit = AddressToAdd

export interface GeoPoint {
  x: number
  y: number
}

export function isGeopoint (obj: any): obj is GeoPoint {
  return typeof obj.x === 'number' && typeof obj.y === 'number'
}

/**
 * The point on Earth that is farthest away from any land.
 * Useful as a nullish value for schools with unknown coordinates.
 */
export const PointNemo = {
  y: -48.877, x: -123.393
}
