<template>
  <div class="image-modal modal-card" :class="{'has-title': title}">
    <header v-if="title" class="modal-card-head">
      <p class="modal-card-title">
        {{ title }}
      </p>
    </header>
    <section class="modal-card-body">
      <img :src="image.fullSizePath">
    </section>
    <!-- <footer class="buttons is-right modal-card-foot">
      <button class="button is-link" @click="$parent.close()">
        Close
      </button>
    </footer> -->
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredImage } from '../api'

@Component({ })
export default class ImageModal extends Vue {
  @Prop({ required: true })
  private title!: string

  @Prop({ required: true })
  private image!: StoredImage

  public mounted () {

  }
}
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/_all.sass';
.image-modal{
  z-index:1;
  padding:0;
  width:auto;
  border-radius: 1em;
  @include desktop() {
    width:auto;
    max-width:70vw;
    max-height:90vh;
  }

  .modal-card-body{
    padding:5px;
    img{
      display:block;
      margin:auto;
    }
  }
  .modal-card-foot{
    padding:10px;
    margin-bottom:0!important;
    .button{
      margin:0;
    }
  }
  &.has-title{
    border-radius: 0;
  }
}
</style>
