import type { CommonLang } from './types'

const lang: CommonLang = {
  'hello': 'hello world',
  'name': 'GB',
  'organisation': 'organisation',
  'Organisation': 'Organisation',
  'district': 'group',
  'District': 'Group',
  'pupil': 'pupil',
  'Pupil': 'Pupil',
  'pupils': 'pupils',
  'Pupils': 'Pupils',
  'class': 'class',
  'classes': 'classes',
  'Class': 'Class',
  'Classes': 'Classes',
  'labelled': 'labelled',
  'licences': 'licences',
  'Licences': 'Licences',
  'licence': 'licence',
  'Licence': 'Licence',
  'scheme': 'scheme',
  'Postcode': 'Postcode',
  'postcode': 'postcode',
  'County': 'County',
  'county': 'county',
  'Maths': 'Maths',
  'maths': 'maths',
  'SchoolDistricts': 'Trusts of groups of schools who want to be able to subscribe for several schools.',
  'SchemeText': 'Scheme',
  'favourite': 'favourite',
  'Favourite': 'Favourite',
  'favourited': 'favourited',
  'Favourited': 'Favourited',
  'favourites': 'favourites',
  'Favourites': 'Favourites',
  'colour': 'colour',
  'Colour': 'Colour',
  'colours': 'colours',
  'Colours': 'Colours',
  'normalised': 'normalised',
  'contactPhone': '+443304609636',
  'contactNumber': '0330 460 9636',
  'contactEmail': 'support@edshed.com',
  'currency': '£',
  'spellingPersonalPrice': '32.50',
  'spellingTeacherPrice': '66.50',
  'spellingPremiumPrice': '2.00',
  'spellingAppPrice': '2.99',
  'pupilsPerSpellingClassroom': '36',
  'natCurriculum': 'National Curriculum',
  'natCurr2': 'National Curriculum',
  'customClass': 'hero',
  'customText': 'Custom lists can be created in our app and in the Word Lists section.<br />Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  'customHide': 'columns is-multiline',
  'spellingCurriculumKey': 'spelling-shed-scheme',
  'spellingCurriculumId': '6',
  'spagCurriculumKey': 'spag-england',
  'spagCurriculumId': '13',
  'mathsCurriculumKey': 'mathshed-teaching',
  'litshedCurriculumKey': 'literacy-shed-plus',
  'phonicsCurriculumKey': 'phonics-shed',
  'phonicsLite1CurriculumKey': 'phonics-shed-supplemental-us',
  'school_year': 'year group',
  '_subject_English': 'English',
  'Primary_School': 'Primary School',
  'MathShed': 'MathShed',
  'mathsPersonalPrice': '32.50',
  'mathsTeacherPrice': '66.50',
  'mathsPremiumPrice': '2.00',
  'mathsAppPrice': '2.99',
  'pupilsPerMathsClassroom': '36',
  'spelt': 'spelt',
  'personalised': 'personalised',
  'personalise': 'personalise',
  'practise': 'practise',
  'Practise': 'Practise',
  'phonics-decodable-1-cost': '176',
  'phonics-decodable-2-cost': '115',
  'phonics-pack-1-cost': '750',
  'phonics-pack-2-cost': '750',
  'phonics-digital-cost': '2.00',
  'phonics-vat': 'plus VAT',
  'phonics-currency': 'gbp',
  'chapter': 'chapter',
  'Chapter': 'Chapter',
  'set': 'set',
  'Set': 'Set',
  'subset': 'subset',
  'Subset': 'Subset',
  'recognise': 'recognise',
  'Recognise': 'Recognise',
  'categorise': 'categorise',
  'Categorise': 'Categorise',
  'nb': 'N.B.',
  'programme': 'programme',
  'Programme': 'Programme',
  'optimise': 'optimise',
  'Optimise': 'Optimise',
  'synchronise': 'synchronise',
  'synchronised': 'synchronised',
  'synchronising': 'synchronising',
  'authorisation': 'authorisation',
  'Authorisation': 'Authorisation',
  'Secondary': 'Secondary',
  'secondary': 'secondary',
  'GPC': 'GPC',
  'grapheme-phoneme': 'grapheme-phoneme',
  'Grapheme-phoneme': 'Grapheme-phoneme',
  'Grapheme-Phoneme': 'Grapheme-Phoneme',
  'Randomise': 'Randomise',
  'randomise': 'randomise',
  'Maximise': 'Maximise',
  'maximise': 'maximise'
}

export default lang
