<template>
  <div>
    <b-field v-if="showTypeSelector" class="mixed-content-types" position="is-right">
      <b-radio-button :value="value && value.type" native-value="text" size="is-small" title="Plain Text" @input="onContentTypeChanged">
        <b-icon icon="text" />
      </b-radio-button>
      <b-radio-button
        :value="value && value.type"
        native-value="html"
        size="is-small"
        title="Rich Text"
        @input="onContentTypeChanged"
      >
        <b-icon icon="format-text" />
      </b-radio-button>
      <b-radio-button
        :value="value && value.type"
        native-value="equation"
        size="is-small"
        title="Equation"
        @input="onContentTypeChanged"
      >
        <b-icon icon="sigma" />
      </b-radio-button>
    </b-field>

    <div v-if="value && value.type === 'text'">
      <b-input v-model="value.value" :placeholder="placeholder" />
    </div>

    <div v-if="value && value.type === 'html'">
      <RichTextInput v-model="value.value" :height="height" />
    </div>

    <div v-if="value && value.type === 'equation'">
      <EquationInput v-model="value.value" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { assertUnreachable } from '../../edshed-common/utils'
import { MixedContent } from '../api'

@Component({ props: ['value', 'placeholder', 'height', 'plainText', 'html'] })
export default class MixedContentInput extends Mixins() {
  private value?: MixedContent

  public mounted () {
    if (!this.value) {
      const newValue: MixedContent = {
        type: 'html',
        value: ''
      }
      this.triggerChange(newValue)
      return
    }
    if (this.$props.html) {
      this.value.type = 'html'
    }
  }

  get showTypeSelector () {
    if (this.$props.plainText) {
      return this.value && this.value.type !== 'text'
    }
    if (this.$props.html) {
      return this.value && this.value.type !== 'html'
    }
    return true
  }

  private onContentTypeChanged (type: MixedContent['type']) {
    if (type === 'text') {
      this.triggerChange({
        type,
        value: this.value ? this.value.value : ''
      })
    } else if (type === 'html') {
      this.triggerChange({
        type,
        value: this.value ? this.value.value : ''
      })
    } else if (type === 'equation') {
      this.triggerChange({
        type,
        value: ''
      })
    } else if (type === 'objects') {
      this.triggerChange({
        type,
        value: ''
      })
    } else {
      assertUnreachable(type)
    }
  }

  private triggerChange (type: MixedContent) {
    this.$emit('input', type)
  }
}
</script>

<style lang="scss" scoped>
.mixed-content-types{
  margin-bottom:0;
  margin-right:10px;
  z-index:1;
  position: relative;
  /deep/.button{
    border-bottom:0;
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  /deep/.button .icon{
    margin:0;
  }

}
</style>
