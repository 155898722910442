import type { UserRelations } from './user'

type Role = 'teacher' | 'admin' | 'pupil'

export class Relationship {
  relationship: UserRelations

  constructor (relationship: UserRelations | ({ object: number } | { subject: number }) & { isMe: true }) {
    if (relationship.isMe) {
      const objectAndSubject = ('object' in relationship) ? relationship.object : relationship.subject
      this.relationship = {
        object: objectAndSubject,
        subject: objectAndSubject,
        isMe: true,
        isTeacher: false,
        isAdmin: false,
        isAdminOfTeacher: false,
        isPupil: false,
        isPupilOfAdmin: false,
        isEmployee: false,
        sameClass: true,
        sameSchool: true
      }
    }
    else {
      this.relationship = relationship
    }
  }

  is (userId: number) {
    if (this.relationship.subject !== userId && this.relationship.object !== userId) {
      throw new Error(`User id ${userId} not provided in relationship object`)
    }

    return new AssertRole(userId, this.relationship)
  }

  isSelf () {
    return this.relationship.isMe
  }
}

class AssertRole {
  user1: number

  relationship: UserRelations

  constructor (userId: number, relationship: UserRelations) {
    this.user1 = userId
    this.relationship = relationship
  }

  a (role: Role) {
    return new AssertObject(this.user1, role, this.relationship)
  }

  an (role: Role) {
    return this.a(role)
  }
}

class AssertObject {
  user1: number
  role: Role

  relationship: UserRelations

  constructor (userId: number, role: Role, relationship: UserRelations) {
    this.user1 = userId
    this.relationship = relationship
    this.role = role
  }

  of (user2: number): boolean {
    if (this.relationship.subject !== user2 && this.relationship.object !== user2) {
      throw new Error(`User id ${user2} not provided in relationship object`)
    }

    if (this.role === 'admin') {
      if (this.user1 === this.relationship.subject) {
        return this.relationship.isAdmin
      }
      else if (this.user1 === this.relationship.object) {
        return this.relationship.isPupilOfAdmin || this.relationship.isEmployee
      }
    }
    else if (this.role === 'teacher') {
      if (this.user1 === this.relationship.subject) {
        return this.relationship.isTeacher
      }
      else if (this.user1 === this.relationship.object) {
        return this.relationship.isPupil
      }
    }
    else if (this.role === 'pupil') {
      if (this.user1 === this.relationship.subject) {
        return this.relationship.isPupil
      }
      else if (this.user1 === this.relationship.object) {
        return this.relationship.isTeacher || (this.relationship.isAdmin && !this.relationship.isAdminOfTeacher)
      }
    }

    throw new Error(`Could not check if ${this.user1} is ${this.role} of ${user2}`)
  }
}
