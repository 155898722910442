import type { DictionaryLanguage, SpellingEducationalRegion } from '../../i18n'
import type { FileUpload, ImageUpload, Locale, StoredFile, StoredImage, Nullable } from './common'
import type { UserRoles } from './user'

export interface DictionaryWordDifficulty {
  calculated_from_scheme: Partial<{ [key in SpellingEducationalRegion]: number }>
}

export const currentDictionaryObjectVersion = 1

export interface DictionaryInfo {
  id: number
  user_id: number
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: DictionaryLanguage
  errors: string[]
  definitions: DictionaryDefinition[]
  difficulty_index: number
  morphemes: string[]
  syllables: string[]
  sentences: string[]
  phonics: GraphemePhonemePair[]
  synonyms: RelatedDictionaryWord[]
  antonyms: RelatedDictionaryWord[]
  flags: DictionaryFlag[]
  image: StoredImage | null
  parent_word: DictionaryInfo | null
  approved: boolean
  hidden: boolean
  owner_username?: string
  times_used?: number
  user_versions?: number
  elements: DictionaryWordElementInfo[]
  as_in: string | null
  ipa_definition: string | null
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
  variant_audio: StoredFile | null
  audio_versions: Partial<{ [key in Locale]: StoredFile }>
  difficulty: DictionaryWordDifficulty | null
  is_valid: boolean
  object_version: number
}
export interface DictionaryInfoEdit extends DictionaryInfo {
  new_image?: any
  new_variant_audio?: any
  new_audio_versions?: Partial<{ [key in Locale]: FileUpload | null }>
}

export type RelatedDictionaryWord = Pick<DictionaryInfo, 'id' | 'word' | 'definitions'> & { related_to_id: number }

export type LetterTileToolTrackedWord = Nullable<Pick<DictionaryInfo, 'id'>> & {
  word: string
  locale: DictionaryLanguage
  times_used: number
  has_definitions: boolean
  has_phonics: boolean
}

export interface GraphemePhonemePair {
  grapheme: string
  phoneme: string
  grapheme_id?: number
}

export interface DictionaryDefinition {
  class: DictionaryDefinitionClass
  definition: string
}

export const DictionaryVisibilityOption = ['hidden', 'public'] as const
export type DictionaryVisibilityOption = typeof DictionaryVisibilityOption[number]

export const DictionaryOwnerOption = ['edshed', 'user'] as const
export type DictionaryOwnerOption = typeof DictionaryOwnerOption[number]

export const DictionaryApprovalOption = ['approved', 'unapproved'] as const
export type DictionaryApprovalOption = typeof DictionaryApprovalOption[number]

export const DictionaryDefinitionClass = ['noun', 'verb', 'adjective', 'adverb', 'preposition', 'pronoun', 'conjunction', 'contraction', 'possessive adjective', 'modal verb', 'transitive verb', 'interjection', 'determiner', 'collective noun', 'exclamation', 'proper noun', 'number'] as const
export type DictionaryDefinitionClass = typeof DictionaryDefinitionClass[number]

export interface DictionaryFilters {
  locale: DictionaryLanguage
  parent_id?: null | number
  visibility?: DictionaryVisibilityOption[]
  owner?: DictionaryOwnerOption[]
  approval?: DictionaryApprovalOption[]
}

export interface AddDictionaryRequest {
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: Locale
  errors: readonly string[]
  definitions: readonly DictionaryDefinition[]
  difficulty_index: number
  morphemes: readonly string[]
  syllables: readonly string[]
  sentences: readonly string[]
  phonics: readonly GraphemePhonemePair[]
  synonyms: readonly Pick<DictionaryInfo, 'id'>[]
  antonyms: readonly Pick<DictionaryInfo, 'id'>[]
  flags: readonly DictionaryFlag[]
  new_image?: ImageUpload | null
  new_variant_audio?: FileUpload | null
  approved?: boolean
  elements: DictionaryWordElementToAdd[]
  as_in: string | null
  ipa_definition: string | null
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
}

export interface AddUserDictionaryRequest {
  word: string
  locale: Locale
  parent_id: number | null
  errors: string[] | null
  definitions: DictionaryDefinition[] | null
  difficulty_index: number
  morphemes: string[] | null
  syllables: string[] | null
  sentences: string[] | null
  phonics: GraphemePhonemePair[] | null
  synonyms: Pick<DictionaryInfo, 'id'>[] | null
  antonyms: Pick<DictionaryInfo, 'id'>[] | null
  new_image?: ImageUpload | null
  new_audio_versions?: Partial<{ [key in Locale]: FileUpload | null }>
}

export interface DictionaryDataToAdd {
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: DictionaryLanguage
  user_id: number
  parent_id: number | null
  errors: string[] | null
  definitions: DictionaryDefinition[] | null
  difficulty_index: number
  morphemes: string[] | null
  syllables: string[] | null
  sentences: string[] | null
  phonics: GraphemePhonemePair[] | null
  synonyms: Pick<DictionaryInfo, 'id'>[] | null
  antonyms: Pick<DictionaryInfo, 'id'>[] | null
  flags: DictionaryFlag[]
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
  new_variant_audio?: FileUpload | null
  hidden: boolean
  approved: boolean
  elements: DictionaryWordElementToAdd[] | null
  as_in: string | null
  ipa_definition: string | null
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
  new_audio_versions?: Partial<{ [key in Locale]: FileUpload }>
}

export interface DictionaryDataToEdit {
  word?: string
  word_class?: DictionaryDefinitionClass | null
  locale?: DictionaryLanguage
  user_id?: number
  parent_id?: number | null
  errors?: string[] | null
  definitions?: DictionaryDefinition[] | null
  difficulty_index?: number
  morphemes?: string[] | null
  syllables?: string[] | null
  sentences?: string[] | null
  phonics?: GraphemePhonemePair[] | null
  synonyms?: Pick<DictionaryInfo, 'id'>[] | null
  antonyms?: Pick<DictionaryInfo, 'id'>[] | null
  flags?: DictionaryFlag[]
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
  new_variant_audio?: FileUpload | null
  hidden?: boolean
  approved?: boolean
  elements?: DictionaryWordElementToAdd[] | null
  as_in?: string | null
  ipa_definition?: string | null
  variant_type?: DictionaryVariantType | null
  variant_phonics?: GraphemePhonemePair[] | null
  new_audio_versions?: Partial<{ [key in Locale]: FileUpload | null }>
}

export const DictionaryDecodabilityFlag = ['Decodable', 'Partially Decodable', 'Eventually Decodable'] as const
export type DictionaryDecodabilityFlag = typeof DictionaryDecodabilityFlag[number]

export const DictionaryFlag = ['HFW', ...DictionaryDecodabilityFlag, 'Junk', 'Pseudo'] as const
export type DictionaryFlag = typeof DictionaryFlag[number]

export interface GetListsForWordFilters {
  locale?: DictionaryLanguage
  user_roles?: readonly UserRoles[]
  school_id?: number
}

// update the enum() in the variant_type fiel of the dictionary_variant table when adding a new variant to the DictionaryVariantType
export const DictionaryVariantType = ['Trap-bath'] as const
export type DictionaryVariantType = typeof DictionaryVariantType[number]

export interface DictionaryVariantToAdd {
  dictionary_id: number
  variant_type: DictionaryVariantType
  variant_phonics: readonly GraphemePhonemePair[] | null
  new_variant_audio?: FileUpload | null
}

export interface DictionaryWordElementToAdd {
  id: number
  rule_id: number
  index: number
  string: string
}

export interface DictionaryWordElementInfo {
  id: number
  rule_id: number
  rule_name: string
  index: number
  string: string
}

type WordClasses = Record<DictionaryDefinitionClass, string>

export const WordClassMap: Partial<Record<DictionaryLanguage, WordClasses>> = {
  af_ZA: {
    'noun': 'selfstandige naamwoord',
    'collective noun': 'versamelnaam',
    'modal verb': 'modale werkwoord',
    'possessive adjective': 'besitlike byvoeglike naamwoord',
    'proper noun': 'eienaam',
    'transitive verb': 'oorganklike werkwoord',
    'adjective': 'byvoeglike naamwoord',
    'adverb': 'bywoord',
    'conjunction': 'voegwoord',
    'contraction': 'sametrekking',
    'determiner': 'bepaler',
    'exclamation': 'uitroep',
    'interjection': 'tussenwerpsel',
    'number': 'getal',
    'preposition': 'voorsetsel',
    'pronoun': 'voornaamwoord',
    'verb': 'werkwoord'
  }
}
