<template>
  <div class="ImageInput">
    <div v-if="image && image.thumbnailPath" class="ImageInput__thumb">
      <img :src="image.thumbnailPath" @click="showPreview">

      <button class="button is-danger is-inline-block is-small ImageInput__clear" title="Clear" @click="onClear">
        <span class="icon is-large">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
          </svg>
        </span>
      </button>
    </div>

    <b-upload v-model="dropFile" :disabled="disabled" drag-drop>
      <div v-if="dropFile" class="ImageInput__drop ImageInput__drop--ready" :class="{'image-preview-mode': preview}">
        <div v-if="imagePreview" class="ImageInput__drop__preview">
          <img :src="imagePreview" draggable="false">
        </div>
        <b-icon icon="check" size="is-large" />
        <span>Image ready for upload</span>
      </div>
      <div v-else class="ImageInput__drop ImageInput__drop--upload">
        <b-icon icon="upload" size="is-large" />
        <span>Drop your files here or click to upload</span>
      </div>
    </b-upload>
  </div>
</template>

<script lang="ts">
// @ts-ignore
import { extname } from 'path-browserify'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredImage } from '../api'
import ImageModal from './ImageModal.vue'

/** Example usage: <ImageInput :record="myRecord" field="image" /> */
@Component({ })
export default class ImageInput extends Vue {
  @Prop({ required: true })
  private readonly record!: any

  @Prop({ required: true })
  private readonly field!: string

  @Prop({ required: false })
  private readonly label: string | undefined

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  @Prop({ required: false, default: false }) // Styles the component to preview the image more prominently
  private readonly preview!: boolean

  @Prop({ required: false, default: null })
  private readonly sizeLimit!: number | null

  private dropFile: File | null = null
  private imagePreview: string = ''

  get image (): StoredImage | null {
    return this.record[this.field]
  }

  formatBytes (bytes: number, decimals: number = 2) {
    if (bytes === 0) { return '0 Bytes' }
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
  }

  public mounted () {
    this.$watch('dropFile', (file: File | null) => {
      if (file === null) {
        this.imagePreview = ''
        return
      }

      if (this.sizeLimit && file.size > this.sizeLimit) {
        this.$emit('error', `File size too large - minimum of ${this.formatBytes(this.sizeLimit)}`)
        this.dropFile = null
        return
      }
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        this.setNewField({
          data: reader.result as string,
          extname: extname(file.name)
        })
        if (this.dropFile) {
          if (['image/jpeg', 'image/png', 'image/gif'].includes(this.dropFile.type)) {
            this.imagePreview = URL.createObjectURL(this.dropFile)
            this.$emit('preview', this.imagePreview)
          }
        }

        this.$emit('image-ready')
      })
    })
  }

  private setNewField (image: any) {
    this.record[this.field] = null
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = image
  }

  public onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }

  private showPreview () {
    this.$buefy.modal.open({
      component: ImageModal,
      props: {
        title: this.label ? `Preview for ${this.label}` : 'Preview',
        image: this.image
      },
      hasModalCard: true,
      events: { },
      onCancel: () => { }
    })
  }
}
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all.sass';
.ImageInput {
  display: flex;
  align-items: center;
  @include mobile{
    flex-wrap:wrap;
  }

  .upload-draggable{
    background-color: #fff;
  }

  .ImageInput__drop {
    z-index:0;
    text-align: center;

    * {
      vertical-align: middle;
    }
    >span{
      z-index:1;
      background-color: rgba(255,255,255,0.6);
      border-radius: 0.8em;
      margin: 0.5em;
    }
    .ImageInput__drop__preview{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:0;
      img{
        height:100%;
        width:auto;
        opacity:.3;
      }
    }

    &.ImageInput__drop--upload,
    &.ImageInput__drop--ready {
      display:flex;
      align-items: center;
      flex-direction: column;

    }

    &.ImageInput__drop--ready {
      color: $blue;
    }
    &.image-preview-mode{
      min-height:8rem;
      font-size: .5em;
      .ImageInput__drop__preview{
        img{
          opacity:1;
        }
      }
      .icon{
        position:absolute;
        top:0;
        left:0;
      }
    }

  }

  .ImageInput__thumb {
    margin-right: 10px;
    text-align: center;
    display:flex;
    @include mobile{
      width:100%;
    }

    img {
      display: block;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .upload.control {
    flex: 1;
    display: block;
  }
}
</style>
