<template>
  <div class="RichTextInput">
    <Editor
      v-if="tinyConfig"
      v-model="text"
      api-key="no-api-key"
      :init="tinyConfig"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable no-undef */
/* eslint-disable import/first */
import { Vue, Component } from 'vue-property-decorator'
// @ts-ignore
import tinymce from 'tinymce'
// @ts-ignore
import Editor from '@tinymce/tinymce-vue'
import EquationModal from './EquationModal.vue'

(window as any).tinymce = tinymce

// #region TinyMCE
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'

// #endregion

@Component({ components: { EquationModal, Editor }, props: ['value', 'height'] })
export default class RichTextInput extends Vue {
  public static StaticURL: string

  // @ts-ignore
  private tinyConfig: tinymce.Settings | null = null
  // @ts-ignore
  private tceEditor: tinymce.Editor | null = null
  private activeNode: Element | null = null

  private text = ''

  public mounted () {
    if (!RichTextInput.StaticURL) {
      // Fallback. Should be set in global...
      RichTextInput.StaticURL = window.location.origin
    }

    // TODO: Needed to fix TinyMCE context menus from appearing in the wrong place...
    // window.scrollTo({ top: 0 })

    this.$watch('value', (text) => {
      this.text = text
    })

    this.$watch('text', (text) => {
      this.$emit('input', text)
    })

    const _this = this

    const height = this.$props.height ? parseInt(this.$props.height) : 300

    this.tinyConfig = {
      base_url: RichTextInput.StaticURL + '/tinymce',
      selector: '#tiny',
      plugins: ['link', 'fullscreen', 'code', 'table', 'lists'],
      content_css: [
        RichTextInput.StaticURL + '/tinymce/tinymce.css',
        RichTextInput.StaticURL + '/guppy/guppy-default.min.css'
      ],
      style_formats: [
        { title: 'Is Link', selector: 'a', classes: 'button is-link' },
        { title: 'Is Primary', selector: 'a', classes: 'button is-primary' },
        { title: 'Is Info', selector: 'a', classes: 'button is-info' },
        { title: 'Is Success', selector: 'a', classes: 'button is-success' },
        { title: 'Is Warning', selector: 'a', classes: 'button is-warning' },
        { title: 'Is Danger', selector: 'a', classes: 'button is-danger' },
        { title: 'Is Number', selector: 'a', classes: 'button is-number' },
        { title: 'Is Dark', selector: 'a', classes: 'button is-dark' },
        { title: 'Is Small', selector: 'a', classes: 'button is-small' },
        {
          title: 'Is Pulled Right',
          selector: 'a',
          classes: 'button is-pulled-right'
        }
        // Add more styles here...
        // Ensure new styles are inside "edshed-common/styles/tinymce/index.sass".
        // Then run: "yarn tinymce:compile-css"
      ],
      height,
      toolbar: 'undo redo | insertequation | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist',
      custom_elements: '~equation',
      promotion: false,
      extended_valid_elements: 'equation[*],span[*],math,semantics,mrow,msup,mrow,mo[*],mi,mn,msup,annotation[*],svg[*],path[*]',
      setup: (editor: any) => {
        this.tceEditor = editor

        editor.ui.registry.addButton('insertequation', {
          text: 'Σ',
          onAction () {
            const node = tinymce.activeEditor?.selection.getNode()

            if (node) {
              _this.activeNode = node
            }
            _this.openEquationEditor()
          }
        })
      }
    }
  }

  public data () {
    return {
      text: this.$props.value
    }
  }

  private openEquationEditor () {
    const node = tinymce.activeEditor?.selection.getNode()

    let equation = ''

    if (node?.tagName === 'EQUATION') {
      // optional chaining syntax not working on Portal :(
      const item = node.attributes.getNamedItem('data-equation')
      equation = item === null ? '' : item.nodeValue || ''
    }

    const modal = this.$buefy.modal.open({
      component: EquationModal,
      props: {
        value: equation
      },
      hasModalCard: true,
      events: {
        'on-insert': (eq: string) => {
          modal.close()
          this.insertEquation(eq)
        }
      },
      onCancel: () => { }
    })
  }

  private insertEquation (eq: string) {
    const doc = new Guppy.Doc(eq, 'text')
    const latex = doc.get_content('latex')

    const html = Guppy.katex.renderToString(latex)

    const equationHtml = `<equation data-equation="${eq}" class="mceNonEditable">${html}</equation>`

    if (this.activeNode && this.activeNode.tagName === 'EQUATION') {
      const equationElement = this.tceEditor!.dom.createFragment(equationHtml).firstChild as Element
      this.tceEditor!.dom.replace(equationElement, this.activeNode)
    } else {
      this.tceEditor!.insertContent(`<p>${equationHtml}</p>`)
    }

    // Hack to refresh the editor to make "noneditable" plugin work again...
    const text = this.tceEditor!.setContent(this.tceEditor!.getContent())
    this.$emit('input', text)
  }
}
</script>

<style lang="scss">
/* Fix overlay of TinyMCE context menus */
.tox-tinymce-aux {
  z-index: 3000 !important;
}
</style>
