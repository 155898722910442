<template>
  <div class="MultiFileAttachmentField">
    <hr>

    <div v-for="(file, index) in value" :key="index">
      <p class="fileHeading">
        File {{ index + 1 }}
      </p>
      <file-attachment-field :value="file" @file-attached="$emit('file-attached', file)" @featured-changed="(val) => handleFeaturedChanged(val, index)">
        <template v-slot:options>
          <slot name="options" :file="file" />
        </template>
      </file-attachment-field>
      <b-checkbox v-if="file.id" v-model="file.deleted" class="is-pulled-right">
        Delete
      </b-checkbox>
      <hr>
    </div>

    <button class="button is-primary" @click="onAdd">
      {{ value.length === 0 ? 'Add file' : 'Add another' }}
    </button>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FileAttachment } from '../api'

@Component({})
export default class MultiFileAttachmentField extends Vue {
  @Prop({ required: true })
  private readonly value!: FileAttachment[]

  private onAdd () {
    this.$emit('input', [...this.value, { name: '', description: '' }])
  }

  private handleFeaturedChanged (isFeatured: boolean, index: number) {
    if (isFeatured) {
      this.value.forEach((file, fileIndex) => {
        if (index !== fileIndex) {
          file.featured = false
        }
      })
    }
  }
}
</script>

<style scoped>
.fileHeading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
hr {
  clear: both;
}
</style>
