<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Insert Equation
      </p>
    </header>
    <section class="modal-card-body">
      <EquationInput v-model="equation" />
    </section>
    <footer class="modal-card-foot">
      <button class="button is-primary" @click="$emit('on-insert', equation)">
        Insert
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import EquationInput from './EquationInput.vue'

@Component({ components: { EquationInput } })
export default class EquationModal extends Vue {
  @Prop({ required: true })
  private value!: string

  private equation = ''

  public mounted () {
    this.equation = this.value
  }
}
</script>
