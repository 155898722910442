<template>
  <div class="LookupInput">
    <b-input
      :value="ellipsis(name, 40)"
      :title="name"
      placeholder="Search..."
      type="search"
      readonly
      icon-pack="ion"
      icon="ion-ios-search"
      expanded
      custom-class="pointer"
      :disabled="disabled"
      @click.native="onLookup"
    />

    <div v-if="isPicking" class="modal is-active">
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Lookup...
          </p>
          <button class="delete" aria-label="close" @click.prevent="onHideLookup" />
        </header>
        <section class="modal-card-body">
          <b-field>
            <b-input
              v-model="tableState.term"
              placeholder="Search..."
              type="search"
              icon-pack="ion"
              icon="ion-ios-search"
              expanded
              @keyup.native.enter="onSearchChange"
            />
            <p class="control">
              <button
                :class="{'button': true, 'is-link': true, 'is-loading': false}"
                @click="onSearchChange"
              >
                Search
              </button>
            </p>
          </b-field>

          <div v-if="multiSelection && selectedItems.length > 0" class="resources-multiselect">
            <b-button
              class="is-primary"
              @click="onAddResources"
            >
              Link {{ selectedItems.length }} resources
            </b-button>
            <b-button
              class="is-primary"
              @click="() => { selectedItems = [] }"
            >
              Clear selected resources
            </b-button>
          </div>

          <div v-if="tableState.term === '' && !hasSearched">
            Enter a search term above and press enter to search
          </div>

          <b-table
            v-if="results.items"
            :data.sync="results.items"
            mobile-cards
            striped
            paginated
            backend-pagination
            backend-sorting
            :per-page="tableState.perPage"
            :total="results.total"
            :default-sort-direction="tableState.dir"
            :default-sort="tableState.sort"
            @page-change="onPageChange"
            @sort="onSortChange"
          >
            <template slot-scope="props">
              <b-table-column field="id" label="ID" sortable>
                <p>{{ typeName === 'quiz_question_sets' ? props.row.ident : props.row.id }}</p>
              </b-table-column>
              <b-table-column :visible="typeName === 'quiz_curriculum_nodes'" field="curriculum_name" label="Curriculum" sortable>
                <p>{{ props.row.curriculum_name }}</p>
              </b-table-column>
              <b-table-column field="name" label="Name" sortable>
                <p>{{ props.row.qualified_name || props.row.name || props.row.title }}</p>
              </b-table-column>
              <b-table-column :visible="props.row.locale !== undefined && props.row.locale" field="locale" label="Locale" sortable>
                <span v-if="props.row.locale">
                  {{ locales.filter(l => l.underscored === props.row.locale)[0].emoji }}
                </span>
              </b-table-column>
              <b-table-column label="Actions" centered>
                <b-checkbox
                  v-if="multiSelection"
                  v-model="selectedItems"
                  :native-value="props.row.id"
                />
                <a
                  v-else
                  class="btn-small btn-small--light"
                  @click="onSelect(props.row)"
                >
                  <b-icon icon="chevron-right" />
                </a>
              </b-table-column>
            </template>
          </b-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator'
import ComponentHelperBase from '../mixins/ComponentHelperBase'
import { TableState, PagedResults, IdRecord, getLookup, Api } from '../api'
import { locales } from '../i18n/index'

@Component({ })
export default class LookupInput extends Mixins(ComponentHelperBase) {
  @Prop({ required: true })
  private readonly typeName!: string

  @Prop({ required: true })
  private readonly value!: number

  @Prop({ required: true })
  private readonly emptyLabel!: string

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  @Prop({ required: false, default: false })
  private readonly idArray!: boolean

  private id: number | null = null
  private name: string | null = null

  private locales = locales

  private isPicking = false
  private hasSearched = false

  private selectedItems: number[] = []

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<IdRecord> = {
    items: [],
    total: 0
  }

  private get multiSelection () {
    return ['curriculum_resources', 'lessons'].includes(this.typeName)
  }

  public mounted () {
    this.$watch('value', (id) => {
      this.id = id
      this.refreshName()
    })

    this.refreshName()
  }

  public data () {
    return {
      id: this.$props.value
    }
  }

  private async refreshName () {
    const lookup = getLookup(this.typeName)

    if (this.id) {
      const record = await lookup.getOne.call(Api, this.id)

      this.name = (record as any).name || (record as any).title
    } else {
      this.name = this.$props.emptyLabel || '(Empty)'
    }
  }

  private async loadData () {
    // prevent blank search on modal load
    if (this.tableState.term === '' && !this.hasSearched) {
      return
    }

    const lookup = getLookup(this.typeName)

    this.results = await lookup.getPaged.call(Api, this.tableState)
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: string) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.hasSearched = true
    this.loadData()
  }

  private onLookup () {
    this.isPicking = true
    this.hasSearched = false

    this.loadData()
  }

  private onSelect (row: IdRecord) {
    const id = this.idArray ? [row.id] : row.id
    this.$emit('input', id, row)

    this.isPicking = false
  }

  private onAddResources () {
    this.$emit('input', this.selectedItems)

    this.selectedItems = []
    this.isPicking = false
  }

  private onHideLookup () {
    this.isPicking = false
  }
}
</script>

<style>
.resources-multiselect {
  margin-top: 1em;
  margin-bottom: 1em;
}
.resources-multiselect button {
  margin-right: 1em;
}
</style>
