<template>
  <div class="FileInput">
    <div v-if="file && file.filePath" class="thumb">
      <div>{{ file.extname.slice(1).toUpperCase() }}</div>
      <button class="button is-danger" @click="onClear">
        Clear
      </button>
    </div>

    <b-upload v-model="dropFile" drag-drop>
      <section class="section">
        <div class="content has-text-centered">
          <div v-if="dropFile && parsed && fileParseType === 'base64'" class="FileInput__drop FileInput__drop--ready">
            <b-icon icon="check" size="is-large" />
            <span>File ready for upload</span>
          </div>
          <div v-else-if="dropFile && parsed && fileParseType === 'arrayBuffer'" class="FileInput__drop FileInput__drop--upload">
            <b-icon icon="file" size="is-large" />
            <span>{{ dropFile.name }} selected</span>
          </div>
          <div v-else-if="dropFile && !parsed" class="FileInput__drop FileInput__drop--upload">
            <b-icon icon="loading" size="is-large" custom-class="mdi-spin" />
            <span>Reading file</span>
          </div>
          <div v-else class="FileInput__drop FileInput__drop--upload">
            <b-icon icon="upload" size="is-large" />
            <span>Drop your files here or click to upload</span>
          </div>
        </div>
      </section>
    </b-upload>
  </div>
</template>

<script lang="ts">
import { extname } from 'path-browserify'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { filetypeinfo } from 'magic-bytes.js'
import { GuessedFile } from 'magic-bytes.js/dist/model/tree'
import { StoredFile } from '../api'

/** Example usage: <file-input :record="myRecord" field="file" /> */
@Component
export default class FileInput extends Vue {
  @Prop({ required: true })
  private record!: any

  @Prop({ required: true })
  private field!: string

  @Prop({ default: 'base64' }) fileParseType!: 'base64' | 'arrayBuffer'

  public dropFile: File | null = null

  private parsed: boolean = false

  @Watch('hasFile', { immediate: true })
  hasFileChanged (val: boolean) {
    this.$emit('has-file', val)
  }

  get hasFile () {
    if (this.file !== null && this.file !== undefined) {
      return true
    } else {
      return this.dropFile !== null
    }
  }

  get file (): StoredFile | null | undefined {
    return this.record[this.field]
  }

  public mounted () {
    this.$watch('dropFile', (file: File) => {
      this.parsed = false

      if (this.fileParseType === 'arrayBuffer') {
        this.processFileAsArrayBuffer(file)
      } else {
        this.processFileAsBase64(file)
      }
    })
  }

  processFileAsArrayBuffer (file: File) {
    const reader = new FileReader()
    this.$emit('reading')
    reader.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        this.$emit('progress', 100 * event.loaded / event.total)
      }
    })

    reader.onload = () => {
      const array = reader.result as ArrayBuffer

      const byteArray = new Uint8Array(array)

      const possibleFileTypes = filetypeinfo(byteArray)

      if (possibleFileTypes.length === 0) {
        throw new Error('Unrecognised file type')
      }

      const expressedExtension = extname(file.name)

      const bestFileType = possibleFileTypes.find((t: GuessedFile) => t.extension === expressedExtension) ?? possibleFileTypes[0]

      this.$emit('progress', 100)
      this.$emit('parsed-data', { buffer: array, extname: bestFileType.extension ?? expressedExtension, mime: bestFileType.mime })
      this.parsed = true
    }

    reader.readAsArrayBuffer(file)
  }

  processFileAsBase64 (file: File) {
    const reader = new FileReader()
    this.$emit('status', 'reading')

    reader.readAsDataURL(file)

    reader.addEventListener('load', () => {
      this.setNewField({
        size: file.size,
        data: reader.result as string,
        extname: extname(file.name)
      })

      this.parsed = true
    })
  }

  private setNewField (file: any) {
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = file
    this.$emit('input', file)
  }

  private onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }
}
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all.sass';
.FileInput {
  display: flex;
}
.thumb {
  margin-right: 10px;
  text-align: center;
}
.thumb div {
  margin-bottom: 10px;
}
.upload.control {
  flex: 1;
  display: block;
}

.FileInput__drop--upload,
.FileInput__drop--ready {
  display:flex;
  align-items: center;
  flex-direction: column;
}

.FileInput__drop--ready {
  color: $green;
}
</style>
