import type { Bit, ImageUpload, MixedContent, StoredImage } from '../common'
import type { QuizQuestionPrintSettings } from '.'

export type QuestionData = SimpleQuestionData | MatchQuestionData

export const SimpleQuestionDataTypes = ['single', 'multi', 'text', 'freetext', 'sort', 'numbertext', 'onetoone', 'sortobjects', 'make', 'numberline', 'compare', 'ordinal'] as const
export type SimpleQuestionDataType = typeof SimpleQuestionDataTypes[number]

export const QuestionDataTypes = [...SimpleQuestionDataTypes, 'match'] as const
export type QuestionDataType = typeof QuestionDataTypes[number]

export const QuestionReadingOption = ['always', 'never', 'optionally'] as const
export type QuestionReadingOption = typeof QuestionReadingOption[number]

/** Common properties to all question types */
export interface QuestionBaseData {
  base_score: number
  time_limit: number
  allow_overtime: boolean
  top_label?: string
  bottom_label?: string
  horizontal?: boolean
  answer_type?: MixedContent['type']
  alt?: string
  case_sensitive?: boolean
  exact_match?: boolean
  print_settings?: QuizQuestionPrintSettings
  video_start_time?: number
  video_end_time?: number
  video_question_parent_id?: number // question id
  read_question?: QuestionReadingOption
}

/** This is every question type except for `match` */
export interface SimpleQuestionData extends QuestionBaseData {
  type: SimpleQuestionDataType
  answers?: readonly QuestionAnswer[]
  is_flagged?: Bit
}

/** Special type for `match` as this has special answers with `left` and `right` properties */
export interface MatchQuestionData extends QuestionBaseData {
  type: 'match'
  answers: readonly QuestionMatchAnswer[]
  is_flagged?: Bit
}

// #region Answer Types
export interface QuestionAnswer {
  content: MixedContent
  image?: StoredImage | null
  new_image?: ImageUpload | null
  correct?: boolean
  feedback?: MixedContent
  feedback_image?: StoredImage | null
  new_feedback_image?: ImageUpload | null
  alt?: string
  sort_order?: number // Auto generated
  is_flagged?: Bit
}

export interface QuestionMatchAnswer {
  left: QuestionAnswer
  right: QuestionAnswer
  sort_order?: number // Auto generated
  is_flagged?: Bit
}

export type GenericQuestionAnswer = QuestionAnswer | QuestionMatchAnswer
// #endregion
