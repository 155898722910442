<template>
  <div class="PromptModal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ title }}
      </p>
    </header>
    <section class="modal-card-body">
      <div class="PromptModal__message">
        <p v-for="(line, index) in getMessageLines()" :key="index" class="PromptModal__line">
          {{ line }}
        </p>
      </div>
      <div class="PromptModal__field">
        <b-field v-if="label" :label="label" horizontal>
          <b-input v-model="value" type="text" :placeholder="label" expanded />
        </b-field>
        <b-field v-else>
          <b-input v-model="value" type="text" expanded />
        </b-field>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        v-for="(button) in getButtons()"
        :ref="`button-${button.key}`"
        :key="button.key"
        type="button"
        :class="`button is-${button.type}`"
        @click="onButtonClick(button)"
      >
        {{ button.label }}
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { ModalButton } from '../mixins/ModalMixin'

@Component({ props: ['title', 'message', 'label', 'defaultValue', 'buttons'] })
export default class PromptModal extends Vue {
  private value = ''

  public async mounted () {
    const buttons = this.getButtons()

    const autoFocusButton = buttons.find(b => b.autoFocus)

    if (autoFocusButton) {
      const buttonElement = this.$refs[`button-${autoFocusButton.key}`] as HTMLButtonElement[]
      await this.$nextTick()
      buttonElement[0].focus()
    }
  }

  public data () {
    return {
      value: this.$props.defaultValue || ''
    }
  }

  public getMessageLines () {
    return this.$props.message.split(/\n/g)
  }

  public getButtons (): ModalButton<string>[] {
    if (this.$props.buttons) {
      return this.$props.buttons
    }

    return [{ key: 'ok', type: 'primary', label: 'OK', autoFocus: true }]
  }

  public onButtonClick (button: ModalButton<string>) {
    this.$emit('on-button-click', button.key, this.value)
  }
}
</script>

<style lang="scss">
.PromptModal {
  /* Move footer buttons to the right (consistency across Buefy versions) */
  &.modal-card .modal-card-foot {
    justify-content: flex-end;
  }

  &__message {
    margin-bottom: 20px;
  }

  &__line {
    margin-bottom: 10px;
  }
}
</style>
