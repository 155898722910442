import gb from './en-gb'
import us from './en-us'
import type { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'HK',
  currency: us.currency,
  spellingPersonalPrice: us.spellingPersonalPrice,
  spellingTeacherPrice: us.spellingTeacherPrice,
  spellingPremiumPrice: us.spellingPremiumPrice,
  spellingAppPrice: us.spellingAppPrice,
  pupilsPerSpellingClassroom: '28',
  customText: 'Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  mathsPersonalPrice: us.mathsPersonalPrice,
  mathsTeacherPrice: us.mathsTeacherPrice,
  mathsPremiumPrice: us.mathsPremiumPrice,
  mathsAppPrice: us.mathsAppPrice,
  pupilsPerMathsClassroom: '28'
}

export default lang
