<template>
  <div class="MultiLookupInput">
    <draggable :value="value" @start="drag=true" @end="drag=false" @input="onSortChange">
      <div v-for="(id, index) in value" :key="index" class="MultiLookupInput__container">
        <LookupInput
          :type-name="$props.typeName"
          :value="id"
          :empty-label="$props.emptyLabel"
          :id-array="true"
          @input="onChange(id, index, $event)"
        />
        <a class="button" @click="deleteLookupInput(id)">
          <i class="ion ion-ios-trash" />
        </a>
      </div>
    </draggable>

    <button class="button is-link" @click="onAdd">
      {{ value.length === 0 ? 'Add' : 'Add another' }}
    </button>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import draggable from 'vuedraggable'

@Component({ components: { draggable } })
export default class MultiLookupInput extends Vue {
  @Prop({ required: true })
  private readonly typeName!: string

  @Prop({ required: true })
  private readonly value!: number[]

  @Prop({ required: true })
  private readonly emptyLabel!: string

  private drag = false

  private onChange (previousId: number, index: number, ids: number[]) {
    const keepExisting = ids.some(id => id === previousId)
    const newIdsToAdd: number[] = ids.filter(id => !this.value.includes(id))

    if (newIdsToAdd.length === 0 && !keepExisting) {
      alert('No new IDs to add')
      return
    }

    let newValue: number[] = this.value

    if (!keepExisting) {
      newValue[index] = newIdsToAdd.shift()!
    }

    newValue = newValue.concat(newIdsToAdd)

    this.$emit('input', newValue)
  }

  private onAdd () {
    this.$emit('input', [...this.value, null])
  }

  private deleteLookupInput (_id: number) {
    this.$emit('input', this.value.filter(id => id !== _id))
  }

  private onSortChange (value: number[]) {
    this.$emit('input', value)
  }
}
</script>

<style lang="scss" scoped>
.MultiLookupInput {
  &__container {
    display: flex;
  }

  .LookupInput {
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
    flex: 1;
  }
}
</style>
