import { FileUpload, ISO8601Date } from './common'

export const FeedbackType = ['bug', 'improvement', 'feature-request', 'general-feedback'] as const
export type FeedbackType = typeof FeedbackType[number]

export const FeedbackSource = ['NewEdshedSite'] as const
export type FeedbackSource = typeof FeedbackSource[number]

export interface CreateFeedbackOptions {
  description: string
  type: FeedbackType
  source: FeedbackSource
  path: string
  file?: FileUpload
}

export type FeedbackItem<TIncludeUser extends boolean> = {
  id: number
  description: string
  type: FeedbackType
  file: string | null
  source: FeedbackSource
  userAgent: string | null
  path: string
  created: ISO8601Date
} & (TIncludeUser extends true ? { user: { id: number, username: string, email: string | null } } : {})

export interface FeedbackQueryOptions {
  type?: FeedbackType
  source?: FeedbackSource
  from?: ISO8601Date
  to?: ISO8601Date
}
