import type { CountryCode, NoteInfo, StripeInvoiceAccountRegion, ZoneCode, SalesPerson, SalesSourceDepts, StripeInvoiceSchoolInfo } from '..'
import type { AddressInfo } from './address'
import type { Bit, FinancialString, ImageUpload, ISO8601Date, StoredImage } from './common'
import { fromFinancialString } from './common'
import { isZoneCode } from './country'

export const StoreItemCategory = ['merch', 'subscription', 'resources', 'training', 'bundle', 'hardware'] as const
export type StoreItemCategory = typeof StoreItemCategory[number]

export const StoreItemSubject = ['spelling', 'number', 'literacy', 'phonics'] as const
export type StoreItemSubject = typeof StoreItemSubject[number]

export const StoreItemShippingType = ['weight', 'unit'] as const
export type StoreItemShippingType = typeof StoreItemShippingType[number]

export const StoreItemSubscriptionTrigger = ['shipped', 'paid', 'promised'] as const
export type StoreItemSubscriptionTrigger = typeof StoreItemSubscriptionTrigger[number]

export const MerchStoreCurrency = ['gbp', 'usd', 'eur', 'aud', 'nzd', 'zar'] as const
export type MerchStoreCurrency = typeof MerchStoreCurrency[number]

export const MerchPurchaseStatus = ['draft', 'finalised', 'promised', 'paid', 'shipped', 'void'] as const
export type MerchPurchaseStatus = typeof MerchPurchaseStatus[number]

export interface MerchStoreTaxRate {
  name: string
  code: string
  rate: number
  region: StripeInvoiceAccountRegion
}

export const merchStoreTaxRates: MerchStoreTaxRate[] = [
  {
    name: '20% (VAT on Income)',
    code: 'OUTPUT2',
    rate: 1.2,
    region: 'GB'
  },
  {
    name: 'No VAT',
    code: 'NONE',
    rate: 1,
    region: 'GB'
  },
  {
    name: 'Zero Rated Income',
    code: 'ZERORATEDOUTPUT',
    rate: 1,
    region: 'GB'
  },
  {
    name: 'Florida State Tax',
    code: 'FLORIDAOUTPUT',
    rate: 1.07,
    region: 'US'
  }
]

export interface MerchPurchaseFilters {
  categories?: StoreItemCategory[]
  subjects?: StoreItemSubject[]
  items?: number[]
  sale_id?: number
  school_id?: number
  status?: MerchPurchaseStatus[]
  unpaid?: boolean
}

export interface StoreItem {
  id: number
  sku: string
  name: string
  image: StoredImage | null
  description: string
  active: boolean
  detail: string
  weight: number
  categories: StoreItemCategory[]
  subjects: StoreItemSubject[]
  require_school: boolean
  shipping_locales: MerchStoreShippingLocale[]
  plan_id: number | null // subscription granted if product is sub type
  subscription_trigger: StoreItemSubscriptionTrigger | null // when to grant subscription
  account_region: StripeInvoiceAccountRegion | null
  stock: MerchStockInfo[]
  bundle_components?: MerchBundleComponent[]
}

export interface StoreItemFilter {
  id: number
  name: string
}

export interface MerchBundleComponent {
  product_id: number
  component_product_id: number
  component_product: StoreItem
  quantity: number
}

export interface MerchBundleComponentToAdd {
  component_product_id: number
  quantity: number
}

export interface MerchStockInfo {
  location_id: number
  location_name: string
  product_id: number
  product_name: string
  quantity: number
  reserved: number
}

export interface MerchStoreShippingLocale {
  product_id: number
  locale: ShippingCode | 'any'
  price: number
  currency: MerchStoreCurrency
  shipping_cost: number | null
  tax_exemptable: boolean
  shipping_type: StoreItemShippingType
  shipping_increment: number
  ship_from_location: number
  xero_tax: MerchStoreTaxRate
  plan_id: number | null
}

export interface GetMerchStoreItemsFilters {
  subjects?: readonly StoreItemSubject[]
  categories?: readonly StoreItemCategory[]
  locale?: ShippingCode
  active?: boolean
  account_region?: StripeInvoiceAccountRegion | null
  ids?: number[]
}

export interface AddMerchProductOptions {
  sku: string
  name: string
  new_image?: ImageUpload | null
  description: string
  active: boolean
  detail: string
  weight: number
  categories: StoreItemCategory[]
  subjects: StoreItemSubject[]
  require_school: boolean
  shipping_locales: Omit<MerchStoreShippingLocale, 'product_id'>[]
  plan_id: number | null // subscription granted if product is sub type
  subscription_trigger: StoreItemSubscriptionTrigger | null // when to grant subscription
  account_region: StripeInvoiceAccountRegion | null
  bundle_components?: MerchBundleComponentToAdd[]
}

export interface MerchBundleStub {
  name: string
  image: StoredImage | null
  quantity: number
  components: {
    name: string
    quantity: number
    image: StoredImage | null
  }[]
}

export interface SetPoNumberOptions {
  id: number
  poNumber?: string
  noteId?: number
}

export type ShippingCode = CountryCode | ZoneCode

export function canMerchOrderTransition (order: MerchPurchase, _new: MerchPurchaseStatus) {
  if (_new === 'draft') {
    return false
  }
  else if (_new === 'finalised') {
    return ['draft'].includes(order.status) && order.shipping_cost !== null
  }
  else if (_new === 'promised') {
    return ['finalised'].includes(order.status) && order.shipping_cost !== null
  }
  else if (_new === 'paid') {
    return (['finalised', 'promised'].includes(order.status) && order.shipping_cost !== null) || (order.status === 'shipped' && order.paid === 0)
  }
  else if (_new === 'shipped') {
    return ['promised', 'paid'].includes(order.status)
  }
  else if (_new === 'void') {
    return ['finalised'].includes(order.status)
  }
  else {
    return false
  }
}

export function canDeleteMerchOrder (order: MerchPurchase) {
  return order.status === 'draft'
}

export function canGenerateInvoice (order: MerchPurchase) {
  if (order.shipping_cost === null) {
    return false
  }

  if (['draft', 'void'].includes(order.status)) {
    return false
  }
  return true
}

export function getShippingCodeForAddress (addr: { zone_code: ZoneCode | null, country_code: CountryCode }): ShippingCode {
  if (addr.zone_code !== null && isZoneCode(addr.zone_code)) {
    return addr.zone_code
  }
  else {
    return addr.country_code
  }
}

export function getShippingDetailsForAddress (product: StoreItem, addr: { zone_code: ZoneCode | null, country_code: CountryCode }): MerchStoreShippingLocale | undefined {
  const zoneLocale = product.shipping_locales.find(l => l.locale === addr.zone_code)

  if (zoneLocale) {
    return zoneLocale
  }

  const countryLocale = product.shipping_locales.find(l => l.locale === addr.country_code)

  if (countryLocale) {
    return countryLocale
  }

  const generalLocale = product.shipping_locales.find(l => l.locale === 'any')

  if (generalLocale) {
    return generalLocale
  }
}

export function getAvailableStockForAddress (product: StoreItem, addr: { zone_code: ZoneCode | null, country_code: CountryCode }): number | undefined {
  const shippingDetails = getShippingDetailsForAddress(product, addr)

  const shippingLocation = shippingDetails?.ship_from_location

  if (!shippingLocation) {
    return undefined
  }

  const stockAtLoc = product.stock.find(s => s.location_id === shippingLocation)

  if (!stockAtLoc) {
    return undefined
  }

  return stockAtLoc.quantity - stockAtLoc.reserved
}

export function calculateShippingCostForCart (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }): number | null {
  const uniqueRules: { type: StoreItemShippingType, threshold: number, cost: number | null, qty: number }[] = []

  const cartCost = calculateTotalCostForCart(cart, addr)

  if (cartCost > 1500) {
    return null
  }

  for (const item of cart) {
    const shippingDetails = getShippingDetailsForAddress(item.item, addr)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.item.sku} for ${addr}`)
    }

    const rule = { cost: shippingDetails.shipping_cost, threshold: shippingDetails.shipping_increment, type: shippingDetails.shipping_type }

    const cachedRule = uniqueRules.find(r => r.cost === rule.cost && r.threshold === rule.threshold && r.type === rule.type)

    if (!cachedRule) {
      uniqueRules.push({ ...rule, qty: item.qty })
    }
    else {
      cachedRule.qty += item.qty
    }
  }

  const totalCost = uniqueRules.reduce((total, rule) => {
    const qty = rule.qty

    if (rule.cost === null || total === null) {
      return null
    }

    let timesToCharge = 0

    if (rule.type === 'unit') {
      timesToCharge = Math.ceil(qty / rule.threshold)
    }
    else if (rule.type === 'weight') {
      timesToCharge = Math.ceil(qty / rule.threshold)
    }

    const cost = rule.cost * timesToCharge

    return total + cost
  }, 0 as number | null)

  if (totalCost === null || totalCost > 30) {
    return null
  }

  return totalCost
}

export function calculateShippingTax (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }, price: number, honourExemptions: boolean, reverseCharge: boolean) {
  if (reverseCharge) {
    return 0
  }

  if (addr.country_code === 'US') {
    return 0
  }

  const lines = calculateShippingTaxLines(cart, addr, honourExemptions, reverseCharge, price)

  return lines.reduce((a, b) => a + b.tax, 0)
  // const maxRate = maxTaxRateForCart(cart, addr, honourExemptions, reverseCharge)

  // return (maxRate - 1) * price
}

export function calculateTotalCostForCart (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }): number {
  const total = cart.reduce((total, item) => {
    const shippingDetails = getShippingDetailsForAddress(item.item, addr)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.item.sku} for ${addr}`)
    }

    const cost = shippingDetails.price * item.qty

    return total + cost
  }, 0)

  return total
}

export function maxTaxRateForCart (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }, honourExemptions: boolean, reverseCharge: boolean) {
  if (reverseCharge) {
    return 1
  }

  const max = cart.reduce((curr, item) => {
    const shippingDetails = getShippingDetailsForAddress(item.item, addr)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.item.sku} for ${addr}`)
    }

    const rate = (honourExemptions && shippingDetails.tax_exemptable) ? 1 : shippingDetails.xero_tax.rate

    return Math.max(curr, rate)
  }, 1)

  return max
}

// Use before invoice finalisation to calculate using dynamic values
export function calculateTaxForCart (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }, honourExemptions: boolean, reverseCharge: boolean): number {
  if (reverseCharge) {
    return 0
  }

  const total = cart.reduce((total, item) => {
    if (item.item.categories.includes('bundle')) {
      const cost = calculateUnitBundleTax(item.item, addr)

      return total + cost * item.qty
    }

    const shippingDetails = getShippingDetailsForAddress(item.item, addr)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.item.sku} for ${addr}`)
    }

    const rate = (honourExemptions && shippingDetails.tax_exemptable) ? 1 : shippingDetails.xero_tax.rate

    const cost = shippingDetails.price * item.qty * (rate - 1)

    return total + cost
  }, 0)

  return total
}

// Use once an invoice has been finalised to calculate based on fixed values
export function calculateTaxForInvoice (invoice: MerchPurchase, honourExemptions: boolean, reverseCharge: boolean): number {
  if (reverseCharge) {
    return 0
  }

  const total = invoice.items.reduce((total, item) => {
    if (item.storeItem.categories.includes('bundle')) {
      const cost = calculateUnitBundleTax(item.storeItem, invoice.billing)

      return total + cost * item.quantity
    }

    const shippingDetails = getShippingDetailsForAddress(item.storeItem, invoice.billing)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.sku} for ${invoice.billing.country_code} ${invoice.billing.zone_code}`)
    }

    const rate = (honourExemptions && shippingDetails.tax_exemptable) ? 1 : shippingDetails.xero_tax.rate

    const cost = fromFinancialString(item.price) * item.quantity * (rate - 1)

    return total + cost
  }, 0)

  return total
}

export function calculateBundleStock (product: { id: number, name: string }, bundleStock: readonly MerchStockInfo[], components: readonly MerchBundleComponent[]): MerchStockInfo[] {
  const locations = new Map<number, Pick<MerchStockInfo, 'location_id' | 'location_name'>>()

  components.forEach(c => c.component_product!.stock.forEach(s => locations.set(s.location_id, { location_id: s.location_id, location_name: s.location_name })))

  return Array.from(locations.values()).map(l => ({
    quantity: components.reduce((min, c) => {
      const locationStock = c.component_product!.stock.find(s => s.location_id === l.location_id)

      if (locationStock) {
        const quantity = locationStock.quantity
        const dividedQuantity = Math.floor(quantity / c.quantity)

        return Math.min(dividedQuantity, min)
      }

      return min
    }, 1000),
    location_id: l.location_id,
    location_name: l.location_name,
    product_id: product.id,
    product_name: product.name,
    reserved: bundleStock.find(s => s.location_id === l.location_id)?.reserved ?? 0
  }))
}

export function calculateUnitTaxLinesForBundle (product: StoreItem, addr: { zone_code: ZoneCode | null, country_code: CountryCode }) {
  if (!product.bundle_components) {
    throw new Error('No components defined')
  }

  const components: MerchBundleComponent[] = []

  function processComponent (comp: MerchBundleComponent) {
    if (comp.component_product.bundle_components) {
      for (const subComp of comp.component_product.bundle_components) {
        processComponent(subComp)
      }
    }
    else {
      components.push(comp)
    }
  }

  for (const comp of product.bundle_components) {
    processComponent(comp)
  }

  if (components.length === 0) {
    throw new Error('No components in bundle')
  }

  const componentShippingRules = components.map((c) => {
    if (!c.component_product) {
      throw new Error('Product details missing')
    }

    const shipping = getShippingDetailsForAddress(c.component_product, addr)

    if (!shipping) {
      throw new Error('Shipping details missing')
    }

    return {
      component: c,
      shipping
    }
  })

  const totalAsIndividuals = componentShippingRules.reduce((tot, com) => {
    return tot + com.shipping.price * com.component.quantity
  }, 0)

  const bundleShipping = getShippingDetailsForAddress(product, addr)

  if (!bundleShipping) {
    throw new Error('No shipping for bundle')
  }

  const discountRate = bundleShipping.price / totalAsIndividuals

  return {
    discount_rate: discountRate,
    lines: componentShippingRules.map(c => ({
      cost: c.shipping.price * discountRate,
      tax_rate: c.shipping.xero_tax,
      quantity: c.component.quantity,
      shipping: c.shipping,
      component: c.component
    }))
  }
}

export function calculateUnitBundleTax (product: StoreItem, addr: { zone_code: ZoneCode | null, country_code: CountryCode }) {
  const taxLines = calculateUnitTaxLinesForBundle(product, addr)

  return taxLines.lines.reduce((tot, l) => tot + (l.quantity * l.cost * (l.tax_rate.rate - 1)), 0)
}

export function calculateShippingTaxLines (cart: CartItem[], addr: { zone_code: ZoneCode | null, country_code: CountryCode }, honourExemptions: boolean, reverseCharge: boolean, shippingCost: number) {
  if (reverseCharge) {
    return []
  }

  const taxMap: Map<string, { tax: MerchStoreTaxRate, quantity: number }> = new Map()

  function addItemToTaxMap (item: StoreItem, quantity: number) {
    const shippingDetails = getShippingDetailsForAddress(item, addr)

    if (!shippingDetails) {
      throw new Error(`Could not find shipping details for ${item.sku} for ${addr}`)
    }

    const taxRate = shippingDetails.xero_tax

    if (!honourExemptions || !shippingDetails.tax_exemptable) {
      if (!taxMap.has(taxRate.code)) {
        taxMap.set(taxRate.code, { tax: taxRate, quantity })
      }
      else {
        taxMap.get(taxRate.code)!.quantity += quantity
      }
    }
  }

  for (const item of cart) {
    if (item.item.categories.includes('bundle')) {
      for (const comp of item.item.bundle_components || []) {
        if (!comp.component_product) {
          throw new Error('Bundle component info missing')
        }

        addItemToTaxMap(comp.component_product, comp.quantity * item.qty)
      }
    }
    else {
      addItemToTaxMap(item.item, item.qty)
    }
  }

  const totalTaxableItems = Array.from(taxMap.values()).reduce((a, b) => a + b.quantity, 0)

  const taxLines = Array.from(taxMap.values()).map(t => ({
    tax_rate: t.tax,
    shipping_percentage: t.quantity / totalTaxableItems,
    cost: shippingCost * t.quantity / totalTaxableItems,
    tax: Math.round(100 * safeConvertTaxRate(t.tax.rate) * shippingCost * t.quantity / totalTaxableItems) / 100
  }))

  return taxLines
}

export function createAddrFromShippingCode (code: ShippingCode | 'any'): { zone_code: ZoneCode | null, country_code: CountryCode } {
  return {
    zone_code: isZoneCode(code) ? code : null,
    country_code: (isZoneCode(code) ? 'any' : code) as CountryCode
  }
}

export interface CartItem {
  item: StoreItem
  qty: number
}

export interface CartItemWithPrice extends CartItem {
  price: number
}

// { sku: 'phonics-intervention-part1', name: 'Phonics Shed Intervention Pack (Part 1)', price: 99.00, image: 'phonics.png', description: 'Part 1 of our Phonics Shed intervention pack. American English only.', detail: 'One set of Phonics Shed Part 1 flashcards including letter formation prompts. Part 1 includes content for pre-kindergarten.', categories: ['subscription', 'resources'], themes: ['phonics'], active: true, requireSchool: true, locales: ['en_US'], subscriptionTrigger: 'paid', testPlanId: 'price_1Jp7OFEwnA0RkkU76DDwnsTl', planId: 'price_1KQUzHEwnA0RkkU7yJCghvz9', accountRegion: 'US', shipsLocally: ['US'] },
// { sku: 'phonics-intervention-part2', name: 'Phonics Shed Intervention Pack (Part 2)', price: 99.00, image: 'phonics.png', description: 'Part 2 of our Phonics Shed intervention pack. American English only.', detail: 'One set of Phonics Shed Part 2 flashcards including letter formation prompts. Part 2 includes content for kindergarten and 1st grade.', categories: ['subscription', 'resources'], themes: ['phonics'], active: true, requireSchool: true, locales: ['en_US'], subscriptionTrigger: 'paid', testPlanId: 'price_1Jp7OZEwnA0RkkU7paqayfjG', planId: 'price_1KQUzZEwnA0RkkU7RfdBlZyA', accountRegion: 'US', shipsLocally: ['US'] },
// { sku: 'phonics-intervention-part1+2', name: 'Phonics Shed Intervention Pack (Part 1 + 2)', price: 149.00, image: 'phonics.png', description: 'Part 1 + 2 of our Phonics Shed intervention pack. American English only.', detail: 'One set each of Phonics Shed Part 1 + 2 flashcards including letter formation prompts. Parts 1 + 2 include content for pre-kindergarten to 1st grade.', categories: ['subscription', 'resources'], themes: ['phonics'], active: true, requireSchool: true, locales: ['en_US'], subscriptionTrigger: 'paid', testPlanId: 'price_1Jp7OxEwnA0RkkU78bExnGfJ', planId: 'price_1KQV0CEwnA0RkkU7YXkuPJYj', accountRegion: 'US', shipsLocally: ['US'] },
// { sku: 'phonics-part1', name: 'Phonics Shed (Pack 1)', price: 650.00, image: 'phonics.png', description: 'Part 1 of our Phonics Shed scheme. British English only.', detail: 'Phonics Shed is a classroom phonics scheme for schools. Phonics Shed is narrative driven, using characters and stories as well as songs and actions to help children learn systematic synthetic phonics. Our pack includes full guidance, story books for the teacher to read as part of lessons, decodable reading books for children (1 copy), flashcards including letter formation prompts and a puppet or our main character, Joe. Part 1 includes content for nursery & reception.', categories: ['subscription', 'resources'], themes: ['phonics'], active: true, requireSchool: true, locales: [], subscriptionTrigger: 'shipped', planId: 'edshed-metered-phonics-yearly-gbp', accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00, outOfStock: false },
// { sku: 'phonics-part2', name: 'Phonics Shed (Pack 2)', price: 650.00, image: 'phonics.png', description: 'Part 2 of our Phonics Shed scheme. British English only.', detail: 'Phonics Shed is a classroom phonics scheme for schools. Phonics Shed is narrative driven, using characters and stories as well as songs and actions to help children learn systematic synthetic phonics. Our pack includes full guidance, story books for the teacher to read as part of lessons, decodable reading books for children (1 copy), flashcards including letter formation prompts and a puppet or our main character, Joe. Part 2 includes content for KS1.', categories: ['subscription', 'resources'], themes: ['phonics'], active: true, requireSchool: true, locales: [], subscriptionTrigger: 'shipped', planId: 'edshed-metered-phonics-yearly-gbp', accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00, outOfStock: false },
// { sku: 'phonics-teaching-books-1', name: 'Teaching Books - Pack 1', price: 255.00, image: 'phonics.png', description: 'Phonics Shed teaching books for chapters 1, 2 & 3', categories: ['resources'], themes: ['phonics'], active: true, requireSchool: false, locales: [], accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00, outOfStock: false },
// { sku: 'phonics-teaching-books-2', name: 'Teaching Books - Pack 2', price: 295.00, image: 'phonics.png', description: 'Phonics Shed teaching books for chapters 4a & 4b', categories: ['resources'], themes: ['phonics'], active: true, requireSchool: false, locales: [], accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00, outOfStock: false },

// { sku: 'phonics-decodable1', name: 'Decodable Reading Books (Part 1)', price: 140.00, image: 'phonics.png', description: 'Our decodable reading book series, to follow our scheme - 70 books.', categories: ['resources'], themes: ['phonics'], active: true, requireSchool: false, locales: [], accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00 },
// { sku: 'phonics-decodable2', name: 'Decodable Reading Books (Part 2)', price: 100.00, image: 'phonics.png', description: 'Our decodable reading book series, to follow our scheme - 50 books. ', categories: ['resources'], themes: ['phonics'], active: true, requireSchool: false, locales: [], accountRegion: 'GB', shipsLocally: ['GB-CHA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS'], shippingCost: 10.00 },
// { sku: 'joe-puppet', name: 'Joe Puppet', price: 20, image: 'joe.png', description: 'Joe puppet for use with Phonics Shed scheme', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'flashcards-large-pack1-uk', name: 'Flash Cards (Large) - Pack 1', price: 95, image: 'phonics.png', description: 'Large sized (approx A4) flashcards for use with Phonics Shed teaching scheme. Pack 1.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'flashcards-large-pack2-uk', name: 'Flash Cards (Large) - Pack 2', price: 95, image: 'phonics.png', description: 'Large sized (approx A4) flashcards for use with Phonics Shed teaching scheme. Pack 2.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'flashcards-small-pack1-uk', name: 'Flash Cards (Small) - Pack 1', price: 40, image: 'phonics.png', description: 'Large sized (approx A6) flashcards for use with Phonics Shed teaching scheme. Chapter 2 &3 .', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'flashcards-small-pack2-uk', name: 'Flash Cards (Small) - Pack 2', price: 40, image: 'phonics.png', description: 'Large sized (approx A6) flashcards for use with Phonics Shed teaching scheme. Chapter 4.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'phonics-wordcards-pack1-uk', name: 'High Frequency Word Cards - Pack 1', price: 40, image: 'phonics.png', description: 'A pack of printed & laminated HFW cards to aid review as part of Phonics Shed lessons. Chapter 2 & 3.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'phonics-wordcards-pack2-uk', name: 'High Frequency Word Cards - Pack 2', price: 40, image: 'phonics.png', description: 'A pack of printed & laminated HFW cards to aid review as part of Phonics Shed lessons. Chapter 4.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'phonics-guidance-folder-uk', name: 'Teacher guidance binder', price: 100, image: 'phonics.png', description: 'A printed version of our teacher guidance pack including all information on teaching strategies, intervention and assesssment, SEN etc. N.B. Does not include lesson plans.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },
// { sku: 'phonics-posters-stretchit-uk', name: 'Blending & Segmenting Posters', price: 10, image: 'phonics.png', description: 'Pack of two A2 posters to prompt for blending and segmenting strategies.', active: true, categories: ['resources'], themes: ['phonics'], requireSchool: false, locales: [], shipsLocally: [], accountRegion: 'GB' },

// { sku: 'stickers-rank-drone', name: 'Assignment Rank Stickers (Drone)', price: 0.99, image: 'stickers-rank-drone.jpg', description: 'A set of 35 stickers for the Drone assignment rank. Each sticker is 37mm round. Stickers may arrive on multiple sheets.', active: true, categories: ['merch'], themes: ['spelling', 'number', 'phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'stickers-rank-worker', name: 'Assignment Rank Stickers (Worker)', price: 0.99, image: 'stickers-rank-worker.jpg', description: 'A set of 35 stickers for the Eorker assignment rank. Each sticker is 37mm round. Stickers may arrive on multiple sheets.', active: true, categories: ['merch'], themes: ['spelling', 'number', 'phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'stickers-rank-soldier', name: 'Assignment Rank Stickers (Soldier)', price: 0.99, image: 'stickers-rank-soldier.jpg', description: 'A set of 35 stickers for the Soldier assignment rank. Each sticker is 37mm round. Stickers may arrive on multiple sheets.', active: true, categories: ['merch'], themes: ['spelling', 'number', 'phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'stickers-rank-royal', name: 'Assignment Rank Stickers (Royal)', price: 0.99, image: 'stickers-rank-royal.jpg', description: 'A set of 35 stickers for the Royal assignment rank. Each sticker is 37mm round. Stickers may arrive on multiple sheets.', active: true, categories: ['merch'], themes: ['spelling', 'number', 'phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'sticker-sheet', name: 'Spelling Shed Stickers', price: 0.99, image: 'stickers.jpg', description: 'A set of 35 Spelling Shed stickers, perfect for rewarding great spelling! Each sticker is 37mm round. Limited stock remaining. Stickers may arrive on multiple sheets.', active: false, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'sticker-sheet2', name: 'Spelling Shed Stickers', price: 0.99, image: 'stickers2.jpg', description: 'A set of 35 Spelling Shed stickers, perfect for rewarding great spelling! Each sticker is 37mm round. Stickers may arrive on multiple sheets.', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'wristband-child', name: 'Spelling Shed Wristband (child)', price: 0.99, image: 'wristband.jpg', description: 'Child sized silicone wristbands in Spelling Shed yellow.', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'pencil', name: 'Spelling Shed Pencil', price: 0.49, image: 'pencils.jpg', description: 'Bright yellow rubber-tipped Spelling Shed pencils (each).', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'bookmark', name: 'Spelling Shed Bookmark', price: 1.49, image: 'bookmark.jpg', description: 'Bright and colourful Spelling Shed bookmarks complete with fuzzy bee.', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'pen', name: 'Spelling Shed Pen', price: 0.49, image: 'pens.jpg', description: 'Spelling Shed ball-point pens (each).', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'eraser', name: 'Spelling Shed Eraser', price: 0.79, image: 'erasers.jpg', description: 'Spelling Shed book-shaped erasers (each).', active: true, categories: ['merch'], themes: ['spelling'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'certificates', name: 'Certificates', price: 2.99, image: '', description: 'A pack of pre-printed reward certificates', active: false, categories: ['merch'], themes: ['spelling', 'number', 'phonics'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'maths-stickers', name: 'Maths Shed Stickers', price: 0.99, image: 'maths-stickers.jpg', description: 'A set of 35 Maths Shed stickers, perfect for rewarding great maths! Each sticker is 37mm round.', active: true, categories: ['merch'], themes: ['number'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'maths-pencil', name: 'Maths Shed Pencil', price: 0.49, image: 'mathspencils.jpg', description: 'Purple rubber-tipped Maths Shed pencils (each).', active: true, categories: ['merch'], themes: ['number'], requireSchool: false, locales: [], shipsLocally: [] },
// { sku: 'maths-pen', name: 'Maths Shed Pen', price: 0.49, image: 'mathspens.jpg', description: 'Maths Shed ball-point pens (each).', active: true, categories: ['merch'], themes: ['number'], requireSchool: false, locales: [], shipsLocally: [] }

export interface MerchPurchase {
  items: readonly MerchPurchaseItem[]
  billing: AddressInfo
  shipping: AddressInfo
  id: number
  timestamp: ISO8601Date
  date_paid: ISO8601Date | null
  billing_address_id: number
  shipping_address_id: number
  total_cost: FinancialString
  shipping_cost: FinancialString | null
  tax: FinancialString
  charge_info: string | null
  user_id: number | null
  school_id: number | null
  school_name: string | null
  shipping_date: Date | null
  school: StripeInvoiceSchoolInfo
  void: Bit
  paid: Bit
  intent_id: string | null
  xero_invoice_id: string | null
  account_region: StripeInvoiceAccountRegion
  notes: NoteInfo[]
  currency: MerchStoreCurrency
  amount_final: FinancialString | null
  status: MerchPurchaseStatus
  source_person: SalesPerson | null
  source_dept: SalesSourceDepts | null
  bundle_components?: MerchBundleComponent[]
  shipping_lines: readonly MerchPurchaseShippingLine[]
  po_number: string | null
  shareable_ident: string | null
}

export interface MerchPurchaseItem {
  storeItem: StoreItem
  id: number
  purchase_id: number
  name: string
  sku: string
  quantity: number
  price: FinancialString
  subscription_id: number | null
  parent_id: number | null
  children: readonly MerchPurchaseItem[]
}

export interface MerchPurchaseShippingLine {
  id: number
  purchase_id: number
  rate: number
  code: string
  cost: number
  tax: number
}

export interface EditDraftPurchaseRequest {
  shipping_cost?: number
  lines: MerchPurchaseLineToEdit[]
}

export interface MerchPurchaseLineToEdit {
  id: number
  name?: string
  quantity?: number
  price?: number
}

export interface MerchAddressToAdd {
  name: string
  email: string
  schoolName: string
  address1: string
  address2: string
  town: string
  county: string
  postcode: string
  country: string
  country_code: CountryCode
  zone_code: ZoneCode | null
}

export interface MerchAddressInfo {
  name: string
  email: string
  school_name: string
  address1: string
  address2: string
  town: string
  county: string
  postcode: string
  country: string
  country_code: CountryCode
  zone_code: ZoneCode | null
}

export interface AddMerchPurchaseRequest {
  totalCost: number
  taxRate: number
  shippingCost: number | null
  tax: number
  billingAddressId: number
  shippingAddressId: number
  cart: CartItem[]
  account_region: StripeInvoiceAccountRegion
  school_id: number | null
}

export interface UpdateMerchStockRequest {
  location_id: number
  quantity: number
}

export interface MerchLocationInfo {
  id: number
  name: string
}

export function merchBundleStubFromStoreItem (item: StoreItem): MerchBundleStub {
  return {
    name: item.name,
    components: (item.bundle_components || []).map(c => ({
      name: c.component_product.name,
      quantity: c.quantity,
      image: c.component_product.image
    })),
    image: item.image,
    quantity: 1
  }
}

export function merchBundleStubFromPurchaseItem (item: MerchPurchaseItem): MerchBundleStub {
  return {
    name: item.name,
    components: (item.children).map(c => ({
      name: c.name,
      quantity: c.quantity,
      image: c.storeItem.image
    })),
    image: item.storeItem.image,
    quantity: item.quantity
  }
}

// from e.g. 1.2 to 0.2
export function safeConvertTaxRate (rate: number) {
  return (rate * 100 - 100) / 100
}
