<template>
  <div class="Modal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ title }}
      </p>
    </header>
    <section class="modal-card-body">
      <div class="Modal__message">
        <p v-for="(line, index) in getMessageLines()" :key="index" class="Modal__line">
          {{ line }}
        </p>
        <pre v-if="console">
          <code>
          {{ console }}
          </code>
        </pre>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        v-for="(button) in getButtons()"
        :ref="`button-${button.key}`"
        :key="button.key"
        type="button"
        :class="`button is-${button.type}`"
        @click="onButtonClick(button)"
      >
        {{ button.label }}
      </button>
    </footer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { ModalButton } from '../mixins/ModalMixin'

@Component({ props: ['title', 'message', 'buttons', 'console'] })
export default class Modal extends Vue {
  public async mounted () {
    const buttons = this.getButtons()

    const autoFocusButton = buttons.find(b => b.autoFocus)

    if (autoFocusButton) {
      const buttonElement = this.$refs[`button-${autoFocusButton.key}`] as HTMLButtonElement[]
      await this.$nextTick()
      buttonElement[0].focus()
    }
  }

  public getMessageLines () {
    return this.$props.message.split(/\n/g)
  }

  public getButtons (): ModalButton<string>[] {
    if (this.$props.buttons) {
      return this.$props.buttons
    }

    return [{ key: 'ok', type: 'primary', label: 'OK', autoFocus: true }]
  }

  public onButtonClick (button: ModalButton<string>) {
    this.$emit('on-button-click', button.key)
  }
}
</script>

<style lang="scss">
.Modal {
  /* Move footer buttons to the right (consistency across Buefy versions) */
  &.modal-card .modal-card-foot {
    justify-content: flex-end;
  }

  &__line {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
