/**
 * The swears array is made of an array of swears run through the JS encoding method `btoa()`
 *
 * Usage:
 * import profanity from '@/edshed-common/utils/profanity'
 * import base64 from 'base-64
 * base64.encode(string.toLowerCase()) === profanity[i]
 */

// AFRIKAANS SWEARS

export const afrikaans_swears = [
  "am91IGRvbm5lcg==",
  "dm9ldHNlaw==",
  "Sm91IG1vZXI=",
  "Zm9r",
  "Zm9rb2Y=",
  "Ymxpa3NlbQ==",
  "cG9lcw==",
  "ZG9vcw==",
  "c2tlZWZibGlrc2Vt",
  "Y3VudA==",
  "dGllZg==",
  "bmFhaQ==",
  "ZG9uZGVy",
  "c2Vrcw==",
  "Z2F0IGxla2tlcg==",
  "d2lw",
  "dHJlayAnbiBob2VuZGVy",
  "dHJlayBuIGhvZW5kZXI=",
  "aG90bm90",
  "Ym9lc21hbg==",
  "a2FmZmVy",
  "dnJlZXQ=",
  "c21lZXI=",
  "c290",
  "YmVmb2s=",
  "Ym9rbmFhaQ==",
  "bHVs",
  "YmFsbGFz",
  "aG9sbmFhaQ==",
  "c25vdG5ldXM=",
  "c3VpcA==",
  "YmFsc2Fr",
  "d2lwZ2F0",
  "cGllbGtvcA==",
  "bW9mZmll",
  "c2tvbGxpZQ==",
  "YmVyZ2ll",
  "Z2xhbnM="
]

export const afrikaans_whitelist = [
  "dmFn",
  "aGVtcA==",
  "bWFtcw==",
  "aG9vcg==",
  "YmVlcg=="
]

